import React from "react"
import { Typography } from "@mui/material"

function Value(props: { value: string | number }) {
  return (
    <Typography variant="body1" component="p">
      {props.value}
    </Typography>
  )
}

export default Value
