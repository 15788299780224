import React from "react"
import Key from "./Key"
import Value from "./Value"

function KeyValue(props: { keyName: string; value: string }) {
  return (
    <>
      <Key keyName={props.keyName} />
      <Value value={props.value} />
    </>
  )
}

export default KeyValue
