import {
  Create,
  CreateProps,
  Datagrid,
  Edit,
  EditButton,
  List,
  ListProps,
  Show,
  ShowButton,
  SimpleForm,
  TextField,
  TextInput,
  ShowProps,
  SimpleShowLayout,
  EditProps,
  Record, required,
} from "react-admin"

export const AddonList = (props: ListProps) => {
  return (
    <List {...props}>
      <Datagrid>
        <TextField source="name" sortable={false} />
        <TextField source="stripeProductId" sortable={false} />
        <ShowButton />
        <EditButton />
      </Datagrid>
    </List>
  )
}

export const AddonCreate = (props: CreateProps) => (
  <Create {...props}>
    <SimpleForm>
      <TextInput
        source="name"
        label="Addon Name"
        validate={[required()]}
      />
      <TextInput
        source="stripeProductId"
        label="Stripe Product Id"
        validate={[required()]}
      />
    </SimpleForm>
  </Create>
)

export const AddonEdit = (props: EditProps) => {
  const transform = ({ applications, ...data }: Record) => {
    return data
  }
  return (
    <Edit {...props} transform={transform}>
      <SimpleForm>
        <TextInput source="id" disabled />
        <TextInput
          source="name"
          label="Addon Name"
          validate={[required()]}
        />
        <TextInput
          source="stripeProductId"
          label="Stripe Product Id"
          validate={[required()]}
        />
      </SimpleForm>
    </Edit>
  )
}

export const AddonShow = (props: ShowProps) => (
  <Show {...props}>
    <SimpleShowLayout>
      <TextField source="id" />
      <TextField source="name" />
      <TextField source="stripeProductId" />
    </SimpleShowLayout>
  </Show>
)
