/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getOrganisation = /* GraphQL */ `
  query GetOrganisation($id: ID!) {
    getOrganisation(id: $id) {
      id
      name
      status
      ownerFirstName
      ownerLastName
      ownerEmail
      ownerPhoneNumber
      cloudformationStackId
      stripeCustomerId
      subdomain
      addons {
        items {
          id
          organisationID
          bookeyAddonID
          createdAt
          updatedAt
        }
        nextToken
      }
      Subscription {
        id
        stripeSubscriptionId
        subscriptionTier {
          id
          stripeProductId
          name
          limitedLocations
          locationsLimit
          limitedMeetingRooms
          meetingRoomsLimit
          limitedTenants
          tenantsLimit
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
        bookeySubscriptionSubscriptionTierId
      }
      Application {
        id
        name
        firstName
        lastName
        email
        phoneNumber
        locationName
        locationAddress
        subdomain
        processed
        subscriptionTier {
          id
          stripeProductId
          name
          limitedLocations
          locationsLimit
          limitedMeetingRooms
          meetingRoomsLimit
          limitedTenants
          tenantsLimit
          createdAt
          updatedAt
        }
        addons {
          nextToken
        }
        createdAt
        updatedAt
        organisationApplicationSubscriptionTierId
      }
      Instance {
        id
        url
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
      organisationSubscriptionId
      organisationApplicationId
      organisationInstanceId
    }
  }
`;
export const listOrganisations = /* GraphQL */ `
  query ListOrganisations(
    $filter: ModelOrganisationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listOrganisations(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        status
        ownerFirstName
        ownerLastName
        ownerEmail
        ownerPhoneNumber
        cloudformationStackId
        stripeCustomerId
        subdomain
        addons {
          nextToken
        }
        Subscription {
          id
          stripeSubscriptionId
          createdAt
          updatedAt
          bookeySubscriptionSubscriptionTierId
        }
        Application {
          id
          name
          firstName
          lastName
          email
          phoneNumber
          locationName
          locationAddress
          subdomain
          processed
          createdAt
          updatedAt
          organisationApplicationSubscriptionTierId
        }
        Instance {
          id
          url
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
        organisationSubscriptionId
        organisationApplicationId
        organisationInstanceId
      }
      nextToken
    }
  }
`;
export const getBookeySubscription = /* GraphQL */ `
  query GetBookeySubscription($id: ID!) {
    getBookeySubscription(id: $id) {
      id
      stripeSubscriptionId
      subscriptionTier {
        id
        stripeProductId
        name
        limitedLocations
        locationsLimit
        limitedMeetingRooms
        meetingRoomsLimit
        limitedTenants
        tenantsLimit
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
      bookeySubscriptionSubscriptionTierId
    }
  }
`;
export const listBookeySubscriptions = /* GraphQL */ `
  query ListBookeySubscriptions(
    $filter: ModelBookeySubscriptionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listBookeySubscriptions(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        stripeSubscriptionId
        subscriptionTier {
          id
          stripeProductId
          name
          limitedLocations
          locationsLimit
          limitedMeetingRooms
          meetingRoomsLimit
          limitedTenants
          tenantsLimit
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
        bookeySubscriptionSubscriptionTierId
      }
      nextToken
    }
  }
`;
export const getBookeyInstance = /* GraphQL */ `
  query GetBookeyInstance($id: ID!) {
    getBookeyInstance(id: $id) {
      id
      url
      createdAt
      updatedAt
    }
  }
`;
export const listBookeyInstances = /* GraphQL */ `
  query ListBookeyInstances(
    $filter: ModelBookeyInstanceFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listBookeyInstances(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        url
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getBookeyTier = /* GraphQL */ `
  query GetBookeyTier($id: ID!) {
    getBookeyTier(id: $id) {
      id
      stripeProductId
      name
      limitedLocations
      locationsLimit
      limitedMeetingRooms
      meetingRoomsLimit
      limitedTenants
      tenantsLimit
      createdAt
      updatedAt
    }
  }
`;
export const listBookeyTiers = /* GraphQL */ `
  query ListBookeyTiers(
    $filter: ModelBookeyTierFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listBookeyTiers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        stripeProductId
        name
        limitedLocations
        locationsLimit
        limitedMeetingRooms
        meetingRoomsLimit
        limitedTenants
        tenantsLimit
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getBookeyAddon = /* GraphQL */ `
  query GetBookeyAddon($id: ID!) {
    getBookeyAddon(id: $id) {
      id
      stripeProductId
      name
      applications {
        items {
          id
          bookeyAddonID
          organisationApplicationID
          createdAt
          updatedAt
        }
        nextToken
      }
      organisations {
        items {
          id
          organisationID
          bookeyAddonID
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const listBookeyAddons = /* GraphQL */ `
  query ListBookeyAddons(
    $filter: ModelBookeyAddonFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listBookeyAddons(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        stripeProductId
        name
        applications {
          nextToken
        }
        organisations {
          nextToken
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getOrganisationApplication = /* GraphQL */ `
  query GetOrganisationApplication($id: ID!) {
    getOrganisationApplication(id: $id) {
      id
      name
      firstName
      lastName
      email
      phoneNumber
      locationName
      locationAddress
      subdomain
      processed
      subscriptionTier {
        id
        stripeProductId
        name
        limitedLocations
        locationsLimit
        limitedMeetingRooms
        meetingRoomsLimit
        limitedTenants
        tenantsLimit
        createdAt
        updatedAt
      }
      addons {
        items {
          id
          bookeyAddonID
          organisationApplicationID
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
      organisationApplicationSubscriptionTierId
    }
  }
`;
export const listOrganisationApplications = /* GraphQL */ `
  query ListOrganisationApplications(
    $filter: ModelOrganisationApplicationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listOrganisationApplications(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        firstName
        lastName
        email
        phoneNumber
        locationName
        locationAddress
        subdomain
        processed
        subscriptionTier {
          id
          stripeProductId
          name
          limitedLocations
          locationsLimit
          limitedMeetingRooms
          meetingRoomsLimit
          limitedTenants
          tenantsLimit
          createdAt
          updatedAt
        }
        addons {
          nextToken
        }
        createdAt
        updatedAt
        organisationApplicationSubscriptionTierId
      }
      nextToken
    }
  }
`;
export const getOrganisationAddons = /* GraphQL */ `
  query GetOrganisationAddons($id: ID!) {
    getOrganisationAddons(id: $id) {
      id
      organisationID
      bookeyAddonID
      organisation {
        id
        name
        status
        ownerFirstName
        ownerLastName
        ownerEmail
        ownerPhoneNumber
        cloudformationStackId
        stripeCustomerId
        subdomain
        addons {
          nextToken
        }
        Subscription {
          id
          stripeSubscriptionId
          createdAt
          updatedAt
          bookeySubscriptionSubscriptionTierId
        }
        Application {
          id
          name
          firstName
          lastName
          email
          phoneNumber
          locationName
          locationAddress
          subdomain
          processed
          createdAt
          updatedAt
          organisationApplicationSubscriptionTierId
        }
        Instance {
          id
          url
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
        organisationSubscriptionId
        organisationApplicationId
        organisationInstanceId
      }
      bookeyAddon {
        id
        stripeProductId
        name
        applications {
          nextToken
        }
        organisations {
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const listOrganisationAddons = /* GraphQL */ `
  query ListOrganisationAddons(
    $filter: ModelOrganisationAddonsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listOrganisationAddons(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        organisationID
        bookeyAddonID
        organisation {
          id
          name
          status
          ownerFirstName
          ownerLastName
          ownerEmail
          ownerPhoneNumber
          cloudformationStackId
          stripeCustomerId
          subdomain
          createdAt
          updatedAt
          organisationSubscriptionId
          organisationApplicationId
          organisationInstanceId
        }
        bookeyAddon {
          id
          stripeProductId
          name
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getApplicationAddons = /* GraphQL */ `
  query GetApplicationAddons($id: ID!) {
    getApplicationAddons(id: $id) {
      id
      bookeyAddonID
      organisationApplicationID
      bookeyAddon {
        id
        stripeProductId
        name
        applications {
          nextToken
        }
        organisations {
          nextToken
        }
        createdAt
        updatedAt
      }
      organisationApplication {
        id
        name
        firstName
        lastName
        email
        phoneNumber
        locationName
        locationAddress
        subdomain
        processed
        subscriptionTier {
          id
          stripeProductId
          name
          limitedLocations
          locationsLimit
          limitedMeetingRooms
          meetingRoomsLimit
          limitedTenants
          tenantsLimit
          createdAt
          updatedAt
        }
        addons {
          nextToken
        }
        createdAt
        updatedAt
        organisationApplicationSubscriptionTierId
      }
      createdAt
      updatedAt
    }
  }
`;
export const listApplicationAddons = /* GraphQL */ `
  query ListApplicationAddons(
    $filter: ModelApplicationAddonsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listApplicationAddons(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        bookeyAddonID
        organisationApplicationID
        bookeyAddon {
          id
          stripeProductId
          name
          createdAt
          updatedAt
        }
        organisationApplication {
          id
          name
          firstName
          lastName
          email
          phoneNumber
          locationName
          locationAddress
          subdomain
          processed
          createdAt
          updatedAt
          organisationApplicationSubscriptionTierId
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
