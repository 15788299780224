import React, { useEffect, useState } from "react"
import {
  Datagrid,
  ChipField,
  SingleFieldList,
  List,
  ListProps,
  ReferenceField,
  Show,
  ShowButton,
  TextField,
  ShowProps,
  TabbedShowLayout,
  Tab,
  useShowController,
  FieldProps,
  useRecordContext,
  ArrayField,
  ShowActionsProps,
  TopToolbar,
  useRefresh,
} from "react-admin"
import { useDispatch, useSelector } from "react-redux"

import {
  getCustomerUpcomingInvoice,
  getStripeSubscriptionDetails,
  getSubscriptionInvoices,
} from "../../service/stripe.service"
import { Button, Typography } from "@mui/material"
import Table from "@mui/material/Table"
import TableBody from "@mui/material/TableBody"
import TableCell from "@mui/material/TableCell"
import TableContainer from "@mui/material/TableContainer"
import TableHead from "@mui/material/TableHead"
import TableRow from "@mui/material/TableRow"
import KeyValue from "../KeyValue"
import {
  formatUnixTimestampDate,
  formatUnixTimestampDateTime,
} from "../../service/date.service"
import { API, graphqlOperation } from "aws-amplify"
import { updateOrganisation } from "../../graphql/mutations"
import { OrganisationStatus } from "../../interfaces/OrganisationStatus"
import { ACTION, getToken, getTokenLoading } from "../../stripe/stripe.reducer"

const BookeyUrlField = (props: FieldProps) => {
  const record = useRecordContext(props)
  return (
    <a
      href={`https://${record.subdomain}.bookey.io`}
      rel="noreferrer"
      target="_blank"
    >{`https://${record.subdomain}.bookey.io`}</a>
  )
}

export const OrganisationList = (props: ListProps) => {
  return (
    <List {...props} bulkActionButtons={true}>
      <Datagrid>
        <TextField source="name" label="Organisation Name" sortable={false} />
        <ReferenceField
          reference="BookeyTiers"
          source="Subscription.bookeySubscriptionSubscriptionTierId"
          label="Tier"
          link="show"
        >
          <ChipField source="name" />
        </ReferenceField>
        <BookeyUrlField label="Url" {...props} />
        <TextField source="ownerFirstName" sortable={false} />
        <TextField source="ownerLastName" sortable={false} />
        <TextField source="status" sortable={false} />
        <ShowButton />
      </Datagrid>
    </List>
  )
}

const OrganisationShowActions = ({ data }: ShowActionsProps) => {
  const refresh = useRefresh()

  const disableOrganisation = async () => {
    if (window.confirm("Are you sure you want to disable the organisation?")) {
      await API.graphql(
        graphqlOperation(updateOrganisation, {
          input: { id: data?.id, status: OrganisationStatus.INACTIVE },
        })
      )
      refresh()
    }
  }
  const enableOrganisation = async () => {
    if (window.confirm("Are you sure you want to enable the organisation?")) {
      await API.graphql(
        graphqlOperation(updateOrganisation, {
          input: { id: data?.id, status: OrganisationStatus.ACTIVE },
        })
      )
      refresh()
    }
  }
  return (
    <TopToolbar>
      {data?.status === OrganisationStatus.ACTIVE ? (
        <Button variant="contained" color="error" onClick={disableOrganisation}>
          Disable Organisation
        </Button>
      ) : (
        <Button variant="contained" color="success" onClick={enableOrganisation}>
          Enable Organisation
        </Button>
      )}
    </TopToolbar>
  )
}

export const OrganisationShow = (props: ShowProps) => {
  const {
    loaded, // boolean that is false until the record is available
    record, // record fetched via dataProvider.getOne() based on the id from the location,
  } = useShowController(props)

  const dispatch = useDispatch()
  const tokenLoading = useSelector(getTokenLoading)
  const token = useSelector(getToken)
  const [stripeSubscriptionDetails, setStripeSubscriptionDetails] = useState<any>()

  useEffect(() => {
    dispatch({ type: ACTION.LOAD_STRIPE_TOKEN })
  }, [dispatch])

  useEffect(() => {
    if (loaded && record && !tokenLoading && token) {
      const stripeSubscriptionId = record.Subscription.stripeSubscriptionId
      loadDataFromStripe(stripeSubscriptionId, token as string).then(() =>
        console.info("Loaded Stripe Data")
      )
    }
  }, [loaded, record, token, tokenLoading])

  const loadDataFromStripe = async (stripeSubscriptionId: string, token: string) => {
    const stripeSubscription = await getStripeSubscriptionDetails(
      stripeSubscriptionId,
      token
    )
    const subscriptionInvoices = await getSubscriptionInvoices(
      stripeSubscriptionId,
      token
    )
    const upcomingInvoice = await getCustomerUpcomingInvoice(
      stripeSubscription.customer,
      token
    )
    setStripeSubscriptionDetails({
      stripeSubscription,
      upcomingInvoice,
      subscriptionInvoices,
    })
  }

  return (
    <Show {...props} actions={<OrganisationShowActions />}>
      <TabbedShowLayout>
        <Tab label="organisation">
          <BookeyUrlField label="Url" addLabel {...props} />
          <TextField source="id" />
          <TextField source="name" label="Organisation Name" />
          <TextField source="subdomain" label="Organisation subdomain" />
          <TextField source="status" label="Organisation Status" />
          <ReferenceField
            reference="BookeyTiers"
            source="Subscription.bookeySubscriptionSubscriptionTierId"
            label="Tier"
            link="show"
          >
            <ChipField source="name" />
          </ReferenceField>
          <TextField source="ownerFirstName" />
          <TextField source="ownerLastName" />
          <TextField source="ownerEmail" />
          <TextField source="ownerPhoneNumber" />
          <TextField source="cloudformationStackId" />
          <TextField source="stripeCustomerId" label="Stripe Customer Id" />
          <TextField
            source="Subscription.stripeSubscriptionId"
            label="Stripe Subscription Id"
          />
          <ArrayField source="addons.items" label="Addons">
            <SingleFieldList linkType={false}>
              <ReferenceField
                source="bookeyAddonID"
                reference="BookeyAddons"
                link={false}
              >
                <ChipField source="name" />
              </ReferenceField>
            </SingleFieldList>
          </ArrayField>
        </Tab>
        <Tab label="Stripe Subscription">
          {stripeSubscriptionDetails ? (
            <>
              <Typography variant="h6" component="p">
                Subscription Details
              </Typography>
              <KeyValue
                keyName={"ID"}
                value={stripeSubscriptionDetails.stripeSubscription.id}
              />
              <KeyValue
                keyName={"Status"}
                value={stripeSubscriptionDetails.stripeSubscription.status.toUpperCase()}
              />
              <KeyValue
                keyName={"Created"}
                value={formatUnixTimestampDate(
                  stripeSubscriptionDetails.stripeSubscription.created
                )}
              />
              <KeyValue
                keyName={"Current period"}
                value={`${formatUnixTimestampDate(
                  stripeSubscriptionDetails.stripeSubscription.current_period_start
                )} - ${formatUnixTimestampDate(
                  stripeSubscriptionDetails.stripeSubscription.current_period_end
                )}`}
              />
              <KeyValue
                keyName={"Trialing until"}
                value={formatUnixTimestampDate(
                  stripeSubscriptionDetails.stripeSubscription.trial_end
                )}
              />
              <Typography variant="h6" component="p">
                Pricing
              </Typography>
              <TableContainer>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>Product ID</TableCell>
                      <TableCell align="right">Quantity</TableCell>
                      <TableCell align="right">Product Price</TableCell>
                      <TableCell>Currency</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {stripeSubscriptionDetails.stripeSubscription.items.data.map(
                      (item: any) => (
                        <TableRow key={item.plan.product}>
                          <TableCell component="th" scope="row">
                            {item.plan.product}
                          </TableCell>
                          <TableCell align="right">{item.quantity}</TableCell>
                          <TableCell align="right">
                            {item.plan.amount / 100}
                          </TableCell>
                          <TableCell>{item.plan.currency}</TableCell>
                        </TableRow>
                      )
                    )}
                  </TableBody>
                </Table>
              </TableContainer>

              <Typography variant="h6" component="p">
                Upcoming invoice
              </Typography>
              <TableContainer>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>Description</TableCell>
                      <TableCell align="right">Quantity</TableCell>
                      <TableCell align="right">Unit Price</TableCell>
                      <TableCell align="right">Amount</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow key="description">
                      <TableCell component="th" scope="row" colSpan={4}>
                        {formatUnixTimestampDate(
                          stripeSubscriptionDetails.upcomingInvoice.period_start
                        )}{" "}
                        -{" "}
                        {formatUnixTimestampDate(
                          stripeSubscriptionDetails.upcomingInvoice.period_end
                        )}
                      </TableCell>
                    </TableRow>
                    {stripeSubscriptionDetails.upcomingInvoice.lines.data.map(
                      (item: any, index: number) => (
                        <TableRow key={item.plan.product + "-" + index}>
                          <TableCell component="th" scope="row">
                            {item.description}
                          </TableCell>
                          <TableCell align="right">{item.quantity}</TableCell>
                          <TableCell align="right">
                            {item.price.unit_amount / 100} {item.plan.currency}
                          </TableCell>
                          <TableCell align="right">
                            {item.amount / 100} {item.plan.currency}
                          </TableCell>
                        </TableRow>
                      )
                    )}
                    <TableRow key="Subtotal">
                      <TableCell
                        component="th"
                        scope="row"
                        colSpan={3}
                        align="right"
                      >
                        <b>Subtotal</b>
                      </TableCell>
                      <TableCell scope="row" align="right">
                        {stripeSubscriptionDetails.upcomingInvoice.subtotal / 100}{" "}
                        {stripeSubscriptionDetails.upcomingInvoice.currency}
                      </TableCell>
                    </TableRow>
                    <TableRow key="Total">
                      <TableCell
                        component="th"
                        scope="row"
                        colSpan={3}
                        align="right"
                      >
                        <b>Total</b>
                      </TableCell>
                      <TableCell scope="row" align="right">
                        {stripeSubscriptionDetails.upcomingInvoice.total / 100}{" "}
                        {stripeSubscriptionDetails.upcomingInvoice.currency}
                      </TableCell>
                    </TableRow>
                    <TableRow key="Amount due">
                      <TableCell
                        component="th"
                        scope="row"
                        colSpan={3}
                        align="right"
                      >
                        <b>Amount due</b>
                      </TableCell>
                      <TableCell scope="row" align="right">
                        {stripeSubscriptionDetails.upcomingInvoice.amount_due / 100}{" "}
                        {stripeSubscriptionDetails.upcomingInvoice.currency}
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>

              <Typography variant="h6" component="p">
                Invoices
              </Typography>
              <TableContainer>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>AMOUNT</TableCell>
                      <TableCell>STATUS</TableCell>
                      <TableCell>INVOICE NUMBER</TableCell>
                      <TableCell>CUSTOMER</TableCell>
                      <TableCell>DUE</TableCell>
                      <TableCell>CREATED</TableCell>
                      <TableCell>DOWNLOAD</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {stripeSubscriptionDetails.subscriptionInvoices.data.map(
                      (item: any) => (
                        <TableRow key={item.id}>
                          <TableCell component="th" scope="row">
                            {item.total / 100} {item.currency}
                          </TableCell>
                          <TableCell>{item.status}</TableCell>
                          <TableCell>{item.number}</TableCell>
                          <TableCell>{item.customer_email}</TableCell>
                          <TableCell>
                            {item.due_date
                              ? formatUnixTimestampDateTime(item.due_date)
                              : "-"}
                          </TableCell>
                          <TableCell>
                            {formatUnixTimestampDateTime(item.created)}
                          </TableCell>
                          <TableCell>
                            <a
                              href={item.invoice_pdf}
                              rel="noreferrer"
                              target="_blank"
                            >
                              Download
                            </a>
                          </TableCell>
                        </TableRow>
                      )
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            </>
          ) : (
            <p>Loading</p>
          )}
        </Tab>
      </TabbedShowLayout>
    </Show>
  )
}
