import { take, call, fork, put } from "redux-saga/effects"
import { ACTION } from "./stripe.reducer"
import { getToken } from "../service/stripe.service"
import { showNotification } from "react-admin"

export function* stripeSaga() {
  yield take(ACTION.LOAD_STRIPE_TOKEN)
  yield fork(function* () {
    try {
      let token: string = yield call(getToken)
      yield put({ type: ACTION.STRIPE_TOKEN_RECEIVED, payload: token })
    } catch (error) {
      yield put({ type: ACTION.STRIPE_TOKEN_REQUEST_FAILED, error })
      console.error(error)
      yield put(showNotification("An error occurred while requesting Stripe token"))
    }
  })
}
