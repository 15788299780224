export enum ACTION {
  "STRIPE_TOKEN_RECEIVED" = "STRIPE_TOKEN_RECEIVED",
  "LOAD_STRIPE_TOKEN" = "LOAD_STRIPE_TOKEN",
  "STRIPE_TOKEN_REQUEST_FAILED" = "STRIPE_TOKEN_REQUEST_FAILED",
}

export interface StripeState {
  token: string | null
  error: string | null
  loading: boolean
}

export default (
  previousState: StripeState = { error: null, token: null, loading: false },
  { type, payload }: { type: ACTION; payload: string }
) => {
  if (type === ACTION.LOAD_STRIPE_TOKEN) {
    return { token: payload, error: null, loading: true }
  }
  if (type === ACTION.STRIPE_TOKEN_RECEIVED) {
    return { token: payload, error: null, loading: false }
  }
  if (type === ACTION.STRIPE_TOKEN_REQUEST_FAILED) {
    return { token: null, error: payload, loading: false }
  }
  return previousState
}

export const getToken = (state: { stripeReducer: StripeState }) =>
  state.stripeReducer.token

export const getTokenLoading = (state: { stripeReducer: StripeState }) =>
  state.stripeReducer.loading
