import axios from "axios";
import {Auth} from "aws-amplify";
import {GetSecretValueCommand, SecretsManagerClient} from '@aws-sdk/client-secrets-manager';

const instance = axios.create({
    baseURL: "https://api.stripe.com/v1",
    method: 'get',
    headers: {
        'Content-Type': 'application/json',
    },
});
const SECRET_ID = 'Bookey/Stripe/SecretApiKey'
const SECRET_REGION = 'eu-central-1'

export const getToken = async () => {
    const credentials = await Auth.currentCredentials();
    const secretsManagerClient = new SecretsManagerClient({
        credentials: Auth.essentialCredentials(credentials),
        region: SECRET_REGION
    });

    const getSecretValueCommand = new GetSecretValueCommand({
        SecretId: SECRET_ID,
    })

    const data = await secretsManagerClient.send(getSecretValueCommand);
    if (!data.SecretString) {
        console.error("No Stripe Secret Key");
        throw new Error("Internal Error - Something went wrong");
    }
    console.debug("Received Stripe Secret")
    return JSON.parse(data.SecretString).StripeApiKey;
}


const authorizedAxios = async (token: string) => {
    instance.defaults.headers.common['Authorization'] = `Bearer ${token}`;
    return instance;
};


export const getStripeSubscriptionDetails = async (subscriptionId: string, token: string) => {
    const axiosClient = await authorizedAxios(token);
    return axiosClient.get(`subscriptions/${subscriptionId}`).then(r => r.data)
}

export const getSubscriptionInvoices = async (subscriptionId: string, token: string) => {
    const axiosClient = await authorizedAxios(token);
    return axiosClient.get(`invoices?subscription=${subscriptionId}`).then(r => r.data)
}
export const getCustomerUpcomingInvoice = async (customerId: string, token: string) => {
    const axiosClient = await authorizedAxios(token);
    return axiosClient.get(`invoices/upcoming?customer=${customerId}`).then(r => r.data)
}
