/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createOrganisation = /* GraphQL */ `
  mutation CreateOrganisation(
    $input: CreateOrganisationInput!
    $condition: ModelOrganisationConditionInput
  ) {
    createOrganisation(input: $input, condition: $condition) {
      id
      name
      status
      ownerFirstName
      ownerLastName
      ownerEmail
      ownerPhoneNumber
      cloudformationStackId
      stripeCustomerId
      subdomain
      addons {
        items {
          id
          organisationID
          bookeyAddonID
          createdAt
          updatedAt
        }
        nextToken
      }
      Subscription {
        id
        stripeSubscriptionId
        subscriptionTier {
          id
          stripeProductId
          name
          limitedLocations
          locationsLimit
          limitedMeetingRooms
          meetingRoomsLimit
          limitedTenants
          tenantsLimit
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
        bookeySubscriptionSubscriptionTierId
      }
      Application {
        id
        name
        firstName
        lastName
        email
        phoneNumber
        locationName
        locationAddress
        subdomain
        processed
        subscriptionTier {
          id
          stripeProductId
          name
          limitedLocations
          locationsLimit
          limitedMeetingRooms
          meetingRoomsLimit
          limitedTenants
          tenantsLimit
          createdAt
          updatedAt
        }
        addons {
          nextToken
        }
        createdAt
        updatedAt
        organisationApplicationSubscriptionTierId
      }
      Instance {
        id
        url
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
      organisationSubscriptionId
      organisationApplicationId
      organisationInstanceId
    }
  }
`;
export const updateOrganisation = /* GraphQL */ `
  mutation UpdateOrganisation(
    $input: UpdateOrganisationInput!
    $condition: ModelOrganisationConditionInput
  ) {
    updateOrganisation(input: $input, condition: $condition) {
      id
      name
      status
      ownerFirstName
      ownerLastName
      ownerEmail
      ownerPhoneNumber
      cloudformationStackId
      stripeCustomerId
      subdomain
      addons {
        items {
          id
          organisationID
          bookeyAddonID
          createdAt
          updatedAt
        }
        nextToken
      }
      Subscription {
        id
        stripeSubscriptionId
        subscriptionTier {
          id
          stripeProductId
          name
          limitedLocations
          locationsLimit
          limitedMeetingRooms
          meetingRoomsLimit
          limitedTenants
          tenantsLimit
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
        bookeySubscriptionSubscriptionTierId
      }
      Application {
        id
        name
        firstName
        lastName
        email
        phoneNumber
        locationName
        locationAddress
        subdomain
        processed
        subscriptionTier {
          id
          stripeProductId
          name
          limitedLocations
          locationsLimit
          limitedMeetingRooms
          meetingRoomsLimit
          limitedTenants
          tenantsLimit
          createdAt
          updatedAt
        }
        addons {
          nextToken
        }
        createdAt
        updatedAt
        organisationApplicationSubscriptionTierId
      }
      Instance {
        id
        url
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
      organisationSubscriptionId
      organisationApplicationId
      organisationInstanceId
    }
  }
`;
export const deleteOrganisation = /* GraphQL */ `
  mutation DeleteOrganisation(
    $input: DeleteOrganisationInput!
    $condition: ModelOrganisationConditionInput
  ) {
    deleteOrganisation(input: $input, condition: $condition) {
      id
      name
      status
      ownerFirstName
      ownerLastName
      ownerEmail
      ownerPhoneNumber
      cloudformationStackId
      stripeCustomerId
      subdomain
      addons {
        items {
          id
          organisationID
          bookeyAddonID
          createdAt
          updatedAt
        }
        nextToken
      }
      Subscription {
        id
        stripeSubscriptionId
        subscriptionTier {
          id
          stripeProductId
          name
          limitedLocations
          locationsLimit
          limitedMeetingRooms
          meetingRoomsLimit
          limitedTenants
          tenantsLimit
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
        bookeySubscriptionSubscriptionTierId
      }
      Application {
        id
        name
        firstName
        lastName
        email
        phoneNumber
        locationName
        locationAddress
        subdomain
        processed
        subscriptionTier {
          id
          stripeProductId
          name
          limitedLocations
          locationsLimit
          limitedMeetingRooms
          meetingRoomsLimit
          limitedTenants
          tenantsLimit
          createdAt
          updatedAt
        }
        addons {
          nextToken
        }
        createdAt
        updatedAt
        organisationApplicationSubscriptionTierId
      }
      Instance {
        id
        url
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
      organisationSubscriptionId
      organisationApplicationId
      organisationInstanceId
    }
  }
`;
export const createBookeySubscription = /* GraphQL */ `
  mutation CreateBookeySubscription(
    $input: CreateBookeySubscriptionInput!
    $condition: ModelBookeySubscriptionConditionInput
  ) {
    createBookeySubscription(input: $input, condition: $condition) {
      id
      stripeSubscriptionId
      subscriptionTier {
        id
        stripeProductId
        name
        limitedLocations
        locationsLimit
        limitedMeetingRooms
        meetingRoomsLimit
        limitedTenants
        tenantsLimit
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
      bookeySubscriptionSubscriptionTierId
    }
  }
`;
export const updateBookeySubscription = /* GraphQL */ `
  mutation UpdateBookeySubscription(
    $input: UpdateBookeySubscriptionInput!
    $condition: ModelBookeySubscriptionConditionInput
  ) {
    updateBookeySubscription(input: $input, condition: $condition) {
      id
      stripeSubscriptionId
      subscriptionTier {
        id
        stripeProductId
        name
        limitedLocations
        locationsLimit
        limitedMeetingRooms
        meetingRoomsLimit
        limitedTenants
        tenantsLimit
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
      bookeySubscriptionSubscriptionTierId
    }
  }
`;
export const deleteBookeySubscription = /* GraphQL */ `
  mutation DeleteBookeySubscription(
    $input: DeleteBookeySubscriptionInput!
    $condition: ModelBookeySubscriptionConditionInput
  ) {
    deleteBookeySubscription(input: $input, condition: $condition) {
      id
      stripeSubscriptionId
      subscriptionTier {
        id
        stripeProductId
        name
        limitedLocations
        locationsLimit
        limitedMeetingRooms
        meetingRoomsLimit
        limitedTenants
        tenantsLimit
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
      bookeySubscriptionSubscriptionTierId
    }
  }
`;
export const createBookeyInstance = /* GraphQL */ `
  mutation CreateBookeyInstance(
    $input: CreateBookeyInstanceInput!
    $condition: ModelBookeyInstanceConditionInput
  ) {
    createBookeyInstance(input: $input, condition: $condition) {
      id
      url
      createdAt
      updatedAt
    }
  }
`;
export const updateBookeyInstance = /* GraphQL */ `
  mutation UpdateBookeyInstance(
    $input: UpdateBookeyInstanceInput!
    $condition: ModelBookeyInstanceConditionInput
  ) {
    updateBookeyInstance(input: $input, condition: $condition) {
      id
      url
      createdAt
      updatedAt
    }
  }
`;
export const deleteBookeyInstance = /* GraphQL */ `
  mutation DeleteBookeyInstance(
    $input: DeleteBookeyInstanceInput!
    $condition: ModelBookeyInstanceConditionInput
  ) {
    deleteBookeyInstance(input: $input, condition: $condition) {
      id
      url
      createdAt
      updatedAt
    }
  }
`;
export const createBookeyTier = /* GraphQL */ `
  mutation CreateBookeyTier(
    $input: CreateBookeyTierInput!
    $condition: ModelBookeyTierConditionInput
  ) {
    createBookeyTier(input: $input, condition: $condition) {
      id
      stripeProductId
      name
      limitedLocations
      locationsLimit
      limitedMeetingRooms
      meetingRoomsLimit
      limitedTenants
      tenantsLimit
      createdAt
      updatedAt
    }
  }
`;
export const updateBookeyTier = /* GraphQL */ `
  mutation UpdateBookeyTier(
    $input: UpdateBookeyTierInput!
    $condition: ModelBookeyTierConditionInput
  ) {
    updateBookeyTier(input: $input, condition: $condition) {
      id
      stripeProductId
      name
      limitedLocations
      locationsLimit
      limitedMeetingRooms
      meetingRoomsLimit
      limitedTenants
      tenantsLimit
      createdAt
      updatedAt
    }
  }
`;
export const deleteBookeyTier = /* GraphQL */ `
  mutation DeleteBookeyTier(
    $input: DeleteBookeyTierInput!
    $condition: ModelBookeyTierConditionInput
  ) {
    deleteBookeyTier(input: $input, condition: $condition) {
      id
      stripeProductId
      name
      limitedLocations
      locationsLimit
      limitedMeetingRooms
      meetingRoomsLimit
      limitedTenants
      tenantsLimit
      createdAt
      updatedAt
    }
  }
`;
export const createBookeyAddon = /* GraphQL */ `
  mutation CreateBookeyAddon(
    $input: CreateBookeyAddonInput!
    $condition: ModelBookeyAddonConditionInput
  ) {
    createBookeyAddon(input: $input, condition: $condition) {
      id
      stripeProductId
      name
      applications {
        items {
          id
          bookeyAddonID
          organisationApplicationID
          createdAt
          updatedAt
        }
        nextToken
      }
      organisations {
        items {
          id
          organisationID
          bookeyAddonID
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateBookeyAddon = /* GraphQL */ `
  mutation UpdateBookeyAddon(
    $input: UpdateBookeyAddonInput!
    $condition: ModelBookeyAddonConditionInput
  ) {
    updateBookeyAddon(input: $input, condition: $condition) {
      id
      stripeProductId
      name
      applications {
        items {
          id
          bookeyAddonID
          organisationApplicationID
          createdAt
          updatedAt
        }
        nextToken
      }
      organisations {
        items {
          id
          organisationID
          bookeyAddonID
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteBookeyAddon = /* GraphQL */ `
  mutation DeleteBookeyAddon(
    $input: DeleteBookeyAddonInput!
    $condition: ModelBookeyAddonConditionInput
  ) {
    deleteBookeyAddon(input: $input, condition: $condition) {
      id
      stripeProductId
      name
      applications {
        items {
          id
          bookeyAddonID
          organisationApplicationID
          createdAt
          updatedAt
        }
        nextToken
      }
      organisations {
        items {
          id
          organisationID
          bookeyAddonID
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const createOrganisationApplication = /* GraphQL */ `
  mutation CreateOrganisationApplication(
    $input: CreateOrganisationApplicationInput!
    $condition: ModelOrganisationApplicationConditionInput
  ) {
    createOrganisationApplication(input: $input, condition: $condition) {
      id
      name
      firstName
      lastName
      email
      phoneNumber
      locationName
      locationAddress
      subdomain
      processed
      subscriptionTier {
        id
        stripeProductId
        name
        limitedLocations
        locationsLimit
        limitedMeetingRooms
        meetingRoomsLimit
        limitedTenants
        tenantsLimit
        createdAt
        updatedAt
      }
      addons {
        items {
          id
          bookeyAddonID
          organisationApplicationID
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
      organisationApplicationSubscriptionTierId
    }
  }
`;
export const updateOrganisationApplication = /* GraphQL */ `
  mutation UpdateOrganisationApplication(
    $input: UpdateOrganisationApplicationInput!
    $condition: ModelOrganisationApplicationConditionInput
  ) {
    updateOrganisationApplication(input: $input, condition: $condition) {
      id
      name
      firstName
      lastName
      email
      phoneNumber
      locationName
      locationAddress
      subdomain
      processed
      subscriptionTier {
        id
        stripeProductId
        name
        limitedLocations
        locationsLimit
        limitedMeetingRooms
        meetingRoomsLimit
        limitedTenants
        tenantsLimit
        createdAt
        updatedAt
      }
      addons {
        items {
          id
          bookeyAddonID
          organisationApplicationID
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
      organisationApplicationSubscriptionTierId
    }
  }
`;
export const deleteOrganisationApplication = /* GraphQL */ `
  mutation DeleteOrganisationApplication(
    $input: DeleteOrganisationApplicationInput!
    $condition: ModelOrganisationApplicationConditionInput
  ) {
    deleteOrganisationApplication(input: $input, condition: $condition) {
      id
      name
      firstName
      lastName
      email
      phoneNumber
      locationName
      locationAddress
      subdomain
      processed
      subscriptionTier {
        id
        stripeProductId
        name
        limitedLocations
        locationsLimit
        limitedMeetingRooms
        meetingRoomsLimit
        limitedTenants
        tenantsLimit
        createdAt
        updatedAt
      }
      addons {
        items {
          id
          bookeyAddonID
          organisationApplicationID
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
      organisationApplicationSubscriptionTierId
    }
  }
`;
export const createOrganisationAddons = /* GraphQL */ `
  mutation CreateOrganisationAddons(
    $input: CreateOrganisationAddonsInput!
    $condition: ModelOrganisationAddonsConditionInput
  ) {
    createOrganisationAddons(input: $input, condition: $condition) {
      id
      organisationID
      bookeyAddonID
      organisation {
        id
        name
        status
        ownerFirstName
        ownerLastName
        ownerEmail
        ownerPhoneNumber
        cloudformationStackId
        stripeCustomerId
        subdomain
        addons {
          nextToken
        }
        Subscription {
          id
          stripeSubscriptionId
          createdAt
          updatedAt
          bookeySubscriptionSubscriptionTierId
        }
        Application {
          id
          name
          firstName
          lastName
          email
          phoneNumber
          locationName
          locationAddress
          subdomain
          processed
          createdAt
          updatedAt
          organisationApplicationSubscriptionTierId
        }
        Instance {
          id
          url
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
        organisationSubscriptionId
        organisationApplicationId
        organisationInstanceId
      }
      bookeyAddon {
        id
        stripeProductId
        name
        applications {
          nextToken
        }
        organisations {
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateOrganisationAddons = /* GraphQL */ `
  mutation UpdateOrganisationAddons(
    $input: UpdateOrganisationAddonsInput!
    $condition: ModelOrganisationAddonsConditionInput
  ) {
    updateOrganisationAddons(input: $input, condition: $condition) {
      id
      organisationID
      bookeyAddonID
      organisation {
        id
        name
        status
        ownerFirstName
        ownerLastName
        ownerEmail
        ownerPhoneNumber
        cloudformationStackId
        stripeCustomerId
        subdomain
        addons {
          nextToken
        }
        Subscription {
          id
          stripeSubscriptionId
          createdAt
          updatedAt
          bookeySubscriptionSubscriptionTierId
        }
        Application {
          id
          name
          firstName
          lastName
          email
          phoneNumber
          locationName
          locationAddress
          subdomain
          processed
          createdAt
          updatedAt
          organisationApplicationSubscriptionTierId
        }
        Instance {
          id
          url
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
        organisationSubscriptionId
        organisationApplicationId
        organisationInstanceId
      }
      bookeyAddon {
        id
        stripeProductId
        name
        applications {
          nextToken
        }
        organisations {
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteOrganisationAddons = /* GraphQL */ `
  mutation DeleteOrganisationAddons(
    $input: DeleteOrganisationAddonsInput!
    $condition: ModelOrganisationAddonsConditionInput
  ) {
    deleteOrganisationAddons(input: $input, condition: $condition) {
      id
      organisationID
      bookeyAddonID
      organisation {
        id
        name
        status
        ownerFirstName
        ownerLastName
        ownerEmail
        ownerPhoneNumber
        cloudformationStackId
        stripeCustomerId
        subdomain
        addons {
          nextToken
        }
        Subscription {
          id
          stripeSubscriptionId
          createdAt
          updatedAt
          bookeySubscriptionSubscriptionTierId
        }
        Application {
          id
          name
          firstName
          lastName
          email
          phoneNumber
          locationName
          locationAddress
          subdomain
          processed
          createdAt
          updatedAt
          organisationApplicationSubscriptionTierId
        }
        Instance {
          id
          url
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
        organisationSubscriptionId
        organisationApplicationId
        organisationInstanceId
      }
      bookeyAddon {
        id
        stripeProductId
        name
        applications {
          nextToken
        }
        organisations {
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const createApplicationAddons = /* GraphQL */ `
  mutation CreateApplicationAddons(
    $input: CreateApplicationAddonsInput!
    $condition: ModelApplicationAddonsConditionInput
  ) {
    createApplicationAddons(input: $input, condition: $condition) {
      id
      bookeyAddonID
      organisationApplicationID
      bookeyAddon {
        id
        stripeProductId
        name
        applications {
          nextToken
        }
        organisations {
          nextToken
        }
        createdAt
        updatedAt
      }
      organisationApplication {
        id
        name
        firstName
        lastName
        email
        phoneNumber
        locationName
        locationAddress
        subdomain
        processed
        subscriptionTier {
          id
          stripeProductId
          name
          limitedLocations
          locationsLimit
          limitedMeetingRooms
          meetingRoomsLimit
          limitedTenants
          tenantsLimit
          createdAt
          updatedAt
        }
        addons {
          nextToken
        }
        createdAt
        updatedAt
        organisationApplicationSubscriptionTierId
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateApplicationAddons = /* GraphQL */ `
  mutation UpdateApplicationAddons(
    $input: UpdateApplicationAddonsInput!
    $condition: ModelApplicationAddonsConditionInput
  ) {
    updateApplicationAddons(input: $input, condition: $condition) {
      id
      bookeyAddonID
      organisationApplicationID
      bookeyAddon {
        id
        stripeProductId
        name
        applications {
          nextToken
        }
        organisations {
          nextToken
        }
        createdAt
        updatedAt
      }
      organisationApplication {
        id
        name
        firstName
        lastName
        email
        phoneNumber
        locationName
        locationAddress
        subdomain
        processed
        subscriptionTier {
          id
          stripeProductId
          name
          limitedLocations
          locationsLimit
          limitedMeetingRooms
          meetingRoomsLimit
          limitedTenants
          tenantsLimit
          createdAt
          updatedAt
        }
        addons {
          nextToken
        }
        createdAt
        updatedAt
        organisationApplicationSubscriptionTierId
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteApplicationAddons = /* GraphQL */ `
  mutation DeleteApplicationAddons(
    $input: DeleteApplicationAddonsInput!
    $condition: ModelApplicationAddonsConditionInput
  ) {
    deleteApplicationAddons(input: $input, condition: $condition) {
      id
      bookeyAddonID
      organisationApplicationID
      bookeyAddon {
        id
        stripeProductId
        name
        applications {
          nextToken
        }
        organisations {
          nextToken
        }
        createdAt
        updatedAt
      }
      organisationApplication {
        id
        name
        firstName
        lastName
        email
        phoneNumber
        locationName
        locationAddress
        subdomain
        processed
        subscriptionTier {
          id
          stripeProductId
          name
          limitedLocations
          locationsLimit
          limitedMeetingRooms
          meetingRoomsLimit
          limitedTenants
          tenantsLimit
          createdAt
          updatedAt
        }
        addons {
          nextToken
        }
        createdAt
        updatedAt
        organisationApplicationSubscriptionTierId
      }
      createdAt
      updatedAt
    }
  }
`;
