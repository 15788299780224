export const formatUnixTimestampDateTime = (unixTimestamp: number) => {
  const a = new Date(unixTimestamp * 1000)
  const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ]
  const year = a.getFullYear()
  const month = months[a.getMonth()]
  const date = a.getDate()
  const hour = a.getHours()
  const min = a.getMinutes()
  const sec = a.getSeconds()
  return date + " " + month + " " + year + " " + hour + ":" + min + ":" + sec
}

export const formatUnixTimestampDate = (unixTimestamp: number) => {
  const a = new Date(unixTimestamp * 1000)
  const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ]
  const year = a.getFullYear()
  const month = months[a.getMonth()]
  const date = a.getDate()
  return date + " " + month + " " + year
}
