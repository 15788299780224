import './App.css';
import Amplify from 'aws-amplify';
import awsconfig from './aws-exports';
import {
  withAuthenticator,
} from '@aws-amplify/ui-react';
import '@aws-amplify/ui/dist/style.css';
import {AmplifyAdmin} from "react-admin-amplify";
import {Resource} from "react-admin";
import * as mutations from "./graphql/mutations";
import * as queries from "./graphql/queries";
import {TierCreate, TierEdit, TierList, TierShow} from "./components/crud/Tier";
import {AddonCreate, AddonEdit, AddonList, AddonShow} from "./components/crud/Addon";
import {OrganisationApplicationList, OrganisationApplicationShow} from "./components/crud/OrganisationApplication";
import {OrganisationList, OrganisationShow} from "./components/crud/Organisation";
import stripeReducer from "./stripe/stripe.reducer";
import {stripeSaga} from "./stripe/stripe.sags";

Amplify.configure(awsconfig);

function App() {
  return (
      <AmplifyAdmin
          operations={{queries, mutations}}
          options={{
            // authGroups: ["SuperAdmin"]
          }}
          customReducers={{stripeReducer}}
          customSagas={[stripeSaga]}
      >
        <Resource name="BookeyTiers"
                  options={{
                    label: 'Bookey Tiers',
                  }}
                  list={TierList} create={TierCreate} edit={TierEdit} show={TierShow}/>
        <Resource name="BookeyAddons"
                  options={{
                    label: 'Bookey Addons',
                  }} list={AddonList} create={AddonCreate} edit={AddonEdit} show={AddonShow}/>
        <Resource name="OrganisationApplications"
                  options={{
                    label: 'Organisation Applications',
                  }}
                  list={OrganisationApplicationList}
                  show={OrganisationApplicationShow}/>
        <Resource name="Organisations"
                  options={{
                    label: 'Organisations',
                  }}
                  list={OrganisationList}
                  show={OrganisationShow}/>
      </AmplifyAdmin>
  );
}

export default withAuthenticator(App);
