import React from "react"
import { Typography } from "@mui/material"

function Key(props: { keyName: string }) {
  return (
    <Typography variant="body2" component="p">
      {props.keyName}
    </Typography>
  )
}

export default Key
