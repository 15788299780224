import React from "react";
import {
    Datagrid,
    ChipField,
    SingleFieldList,
    List, ListProps,
    ReferenceField,
    Show,
    ShowButton,
    TextField,
    BooleanField, ShowProps, SimpleShowLayout, ArrayField,
} from "react-admin";

export const OrganisationApplicationList = (props: ListProps) => {
    return (
        <List {...props}
              bulkActionButtons={true}
        >
            <Datagrid>
                <TextField source="name" label="Organisation Name" sortable={false}/>
                <TextField source="firstName" label="First Name" sortable={false}/>
                <TextField source="lastName" label="Last Name" sortable={false}/>
                <TextField source="email" label="Email" sortable={false}/>
                <BooleanField source="processed" sortable={false}/>
                <ShowButton/>
            </Datagrid>
        </List>
    );
};


export const OrganisationApplicationShow = (props: ShowProps) => (
    <Show {...props}>
        <SimpleShowLayout>
            <TextField source="id"/>
            <TextField source="name" label="Organisation Name"/>
            <TextField source="firstName" label="Applicant First Name"/>
            <TextField source="lastName" label="ApplicantLast Name"/>
            <TextField source="email" label="Applicant Email"/>
            <TextField source="phoneNumber" label="Applicant Email"/>
            <TextField source="locationName"/>
            <TextField source="locationAddress"/>
            <TextField source="subdomain"/>
            <BooleanField
                source="processed"
            />
            <ReferenceField
                source="organisationApplicationSubscriptionTierId"
                reference="BookeyTiers"
                label="Subscription Tier"
                link="show"
            >
                <TextField source="name"/>
            </ReferenceField>
            <ArrayField source="addons.items" label="Addons">
                <SingleFieldList linkType={false} >
                    <ReferenceField source="bookeyAddonID" reference="BookeyAddons" link={false}>
                        <ChipField source="name" />
                    </ReferenceField>
                </SingleFieldList>
            </ArrayField>
        </SimpleShowLayout>
    </Show>
);