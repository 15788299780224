import React, {} from "react";
import {
    Create, CreateProps,
    Datagrid,
    Edit,
    EditButton,
    List, ListProps,
    NumberField,
    NumberInput,
    Show,
    ShowButton,
    SimpleForm,
    BooleanInput,
    TextField,
    TextInput, BooleanField, ShowProps, SimpleShowLayout
} from "react-admin";

export const TierList = (props: ListProps) => {
    return (
        <List {...props} >
            <Datagrid>
                <TextField source="name" sortable={false}/>
                <TextField source="stripeProductId" sortable={false}/>
                <BooleanField
                    source="limitedLocations"
                    sortable={false}
                />
                <NumberField source="locationsLimit" sortable={false}/>
                <BooleanField
                    source="limitedMeetingRooms"
                    sortable={false}
                />
                <NumberField source="meetingRoomsLimit" sortable={false}/>
                <BooleanField
                    source="limitedTenants"
                    sortable={false}
                />
                <NumberField source="tenantsLimit" sortable={false}/>
                <ShowButton/>
                <EditButton/>
            </Datagrid>
        </List>
    );
};


export const TierCreate = (props: CreateProps) => (
    <Create {...props}>
        <SimpleForm>
            <TextInput
                source="name"
                label="Tier Name"
                // validate={validateCustomer}
            />
            <TextInput
                source="stripeProductId"
                label="Stripe Product Id"
                // validate={validateCustomer}
            />
            <BooleanInput
                source="limitedLocations"
                label="Limit number of Locations"
            />
            <NumberInput label="Maximum number of locations"
                         source="locationsLimit"/>

            <BooleanInput
                source="limitedMeetingRooms"
                label="Limit number of Meeting Rooms"
            />
            <NumberInput label="Maximum number of Meeting Rooms"
                         source="meetingRoomsLimit"/>

            <BooleanInput
                source="limitedTenants"
                label="Limit number of Tenants"
            />
            <NumberInput label="Maximum number of Tenants"
                         source="tenantsLimit"/>
        </SimpleForm>
    </Create>
);

export const TierEdit = (props: CreateProps) => (
    <Edit {...props}>
        <SimpleForm>
            <TextInput source="id" disabled/>
            <TextInput
                source="name"
                label="Tier Name"
                // validate={validateCustomer}
            />
            <TextInput
                source="stripeProductId"
                label="Stripe Product Id"
                // validate={validateCustomer}
            />
            <BooleanInput
                source="limitedLocations"
                label="Limit number of Locations"
            />
            <NumberInput label="Maximum number of locations"
                         source="locationsLimit"/>

            <BooleanInput
                source="limitedMeetingRooms"
                label="Limit number of Meeting Rooms"
            />
            <NumberInput label="Maximum number of Meeting Rooms"
                         source="meetingRoomsLimit"/>

            <BooleanInput
                source="limitedTenants"
                label="Limit number of Tenants"
            />
            <NumberInput label="Maximum number of Tenants"
                         source="tenantsLimit"/>
        </SimpleForm>
    </Edit>
);

export const TierShow = (props: ShowProps) => (
    <Show {...props}>
        <SimpleShowLayout>
            <TextField source="id"/>
            <TextField source="name"/>
            <TextField source="stripeProductId"/>
            <BooleanField
                source="limitedLocations"
                sortable={false}
            />
            <NumberField source="locationsLimit"/>
            <BooleanField
                source="limitedMeetingRooms"

            />
            <NumberField source="meetingRoomsLimit"/>
            <BooleanField
                source="limitedTenants"
                sortable={false}
            />
            <NumberField source="tenantsLimit"/>
        </SimpleShowLayout>
    </Show>
);